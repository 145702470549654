<template>
  <div>
    <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row mt-1">
        <div class="col">
          <TransactionsTable v-if="transactions.length !== 0" :per-page="25" title="Dernières transactions"
                             :data="transactions"/>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>

import TransactionsTable from "@/views/Tables/TransactionsTable";

import {Utils} from "@/utils/utils";

export default {
  name: "Transactions",
  components: {TransactionsTable},
  data: () => ({
    key: '',
    api: '',
    employee: null,
    transactions: []
  }),
  methods: {
    async generateToken() {
      this.token = Utils.token()
    },
    async fetchTransactions() {
      await this.generateToken()
      fetch(`${this.api}/transactions`, {
        method: 'GET',
        headers: {
          'accept-type': 'application/json',
          'access-token': this.token,
          'employee': this.employee.account.accountNumber
        }
      }).then(res => res.json()).then(res => {
        this.transactions = res
      }).catch(err => console.error(err))
    }
  },
  mounted() {
    this.key = Utils.jwtTokenKey()
    this.api = Utils.apiUrl()
    this.employee = JSON.parse(localStorage.getItem('USER'))
    this.fetchTransactions()
  }
}
</script>

<style scoped>

</style>